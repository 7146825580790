import React from "react";
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import { JobLocation, JobSalary, JobOTE } from "@components/jobs/results/job/functions";

import  "./assets/jobs_item.scss";

import ArrowIcon from '@icons/arrow.inline.svg';
// import SaveIcon from '@icons/heart.inline.svg';

const truncate = (str) => {
    return str.length > 40 ? str.substring(0, 40) + "..." : str;
}

const JobHit = (props) => {
    return (
        <Link to={`/job-details/${props.slug}-${props.jobid}`} className="jobs-list__item arrow-animation mb-15 mb-md-20" ref={props.innerRef}>
            <h5 className="jobs-list__item__title font-weight-bold mb-0">{truncate(props.title)}</h5>
            <span className="jobs-list__item__icons jobs-list__item__icons__mobile">
                {/* <SaveIcon className="icon-heart" /> */}
                <ArrowIcon className="icon-arrow-right animate-icon-arrow-right" />
            </span>
            <span className="jobs-list__item__location"><JobLocation jobData={props} /></span>
            <span className="jobs-list__item__salary"><JobSalary jobData={props} /></span>
            <span className="jobs-list__item__ote"><JobOTE jobData={props} /></span>
            <span className="jobs-list__item__icons jobs-list__item__icons__desktop">
                {/* <SaveIcon className="icon-heart" /> */}
                <ArrowIcon className="icon-arrow-right animate-icon-arrow-right" />
            </span>
        </Link>
    )
}

// Check all the prop types
JobHit.propTypes = {
    sectionClass: PropTypes.string,
    jobsData: PropTypes.array
};

// Specifies the default values for props:
JobHit.defaultProps = {
    sectionClass: '',
    jobsData: [
        {
            title: 'Business Development Manager',
            Location: "Greenwich, London",
            Salary: "£80,000+",
            Link: "/"
        },
        {
            title: 'Tenancy Manager',
            Location: "Stoke-On-Trent, Staffordshire ",
            Salary: "£80,000+",
            Link: "/"
        }
    ]
};

export default React.forwardRef((props, ref) => <JobHit 
  innerRef={ref} {...props}
/>);

