import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackgroundImage from 'gatsby-background-image';
import ArticleText from "@components/article-text/only-module-text-html";
// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import ContentHeaderImageBtns from "@components/modules/content-header-image/_content-header-image-btns";
import VideoModal from "@components/modal/video-modal-img";
import  "./assets/_index.scss";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const VideoTextModule = (props) => {
    let contAnim = useRef(null);
    let imgAnim = useRef(null);
    let textAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.to(contAnim, {
            duration: 0,
            css: {visibility: 'visible'}
        })
        .from(imgAnim, {
            opacity: 0,
            scale: 0.8,
            y: 100
        })
        .from(textAnim, {
            opacity: 0,
            x: -200
        })
    }, [])

    return(
        <div className={`section video-text-module${props.sectionClass}`} ref={el => contAnim = el}>
            <Container>
                <Row className="d-flex">
                    <Col xs={12} xl={{ span: 6, offset: 1 }} className="video-text-module_first" ref={el => imgAnim = el}>
                        {props.videoask ? (
                    <div className="videoask-frame">
                        <iframe src={props.videoaskUrl ? props.videoaskUrl : ""} title="crux careers" allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;" width="100%" height="330px" border="0"></iframe>
                    </div>
                ) : (
                    props.showVideo ? (
                            <VideoModal
                                imgUrl={props.imgUrl}
                                showVideo={props.showVideo}
                                videoId={props.videoId}
                                videoChannel={props.videoChannel}
                            />
                        ) : (
                            <BackgroundImage
                                Tag="div"
                                className="video-text-module__image mb-22 mb-md-0 mt-md-60 mt-xl-0"
                                fluid={props.imgUrl}
                                loading="eager"
                                role="img"
                            />
                ))}
                    </Col>
                    <Col xs={12} xl={5} className="video-text-module_second" ref={el => textAnim = el}>
                        <h2 className="section-title h5 mb-14 mb-md-22 mb-xl-30">{props.Title}</h2>
                        <div className="video-text-module__text">
                            <ArticleText
                                html={props.Text}
                                // sectionClass=" mt-33 mb-34 mt-md-52 mb-md-60 mb-xl-96"
                            />
                            <ContentHeaderImageBtns
                                btnContainerClass="mb-20 mb-md-40"
                                btnClass={props.btnClass}
                                ButtonData={props.ButtonData}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

// Check all the prop types
VideoTextModule.propTypes = {
    sectionClass: PropTypes.string,
    Title: PropTypes.string,
    Text: PropTypes.string,
    ButtonLink: PropTypes.string,
    ButtonLabel: PropTypes.string,
    ButtonData: PropTypes.array
};
  
// Specifies the default values for props:
VideoTextModule.defaultProps = {
    sectionClass: ' mt-40 mb-34 mt-md-51 mb-md-54 mt-xl-131 mb-xl-139',
    Title: 'We’re transparent, we’re on your side and we charge appropriately and fairly.',
    Text: '<p>Vestibulum vitae sem purus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean ante nunc, posuere ut nisi ac.</p>',
    btnClass: 'btn btn-outline-secondary mt-23 mt-md-31 mt-xl-51',
    ButtonData: [
        {
            id: 1231,
            targetLink: null,
            externalLink: null,
            Label: 'Upload Your CV',
            page: {
                Slug: 'candidates/upload-your-cv'
            },
            btnClass: 'btn btn-primary'
        }
    ],
    showVideo: false,
    videoId: '',
    videoChannel: ''
};

export default VideoTextModule